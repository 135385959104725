import Alpine from 'alpinejs';
import htmx from 'htmx.org';
import collapse from '@alpinejs/collapse'

Alpine.plugin(collapse);

window.Alpine = Alpine;
window.htmx = htmx;

if(nonce !== undefined) {
    document.addEventListener('htmx:configRequest', function(event) {
        event.detail.headers['X-WP-Nonce'] = nonce;
    });
}

document.addEventListener('DOMContentLoaded', function() {
    Alpine.start();
});